<template>
  <div>
    <mkp-cart-table v-if="content.length > 0" />
    <mkp-cart-empty v-else />
  </div>
</template>

<script>
import MkpCartTable from "../../components/views/cart/mkp-cart-table";
import MkpCartEmpty from "../../components/views/cart/mkp-cart-empty";
export default {
  name: "Cart",
  components: { MkpCartEmpty, MkpCartTable },
  computed: {
    content() {
      return this.$store.getters.getCartContents;
    }
  }
};
</script>

<style scoped></style>
