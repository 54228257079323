<template>
  <section class="container-cart">
    <h1 class="cart-title">{{ $t("cart.table.title") }}</h1>
    <mkp-table
      :data="gridData"
      :columns="gridColumns"
      :filter-key="searchQuery"
    >
      <template v-slot:actions="{ row }">
        <mkp-button class="delete-product" @click="deleteItem(row)">
          <span class="lnr lnr-trash"></span>
        </mkp-button>
      </template>
    </mkp-table>

    <mkp-button class="cart-validate" @click="loadForm('contact')">{{
      $t("cart.table.button")
    }}</mkp-button>
  </section>
</template>

<script>
import MkpTable from "../../complex/table/mkp-table";
import MkpButton from "../../simple/button/mkp-button";
import "./cart-scss/mkp-cart-table.scss";
import { getValidBaseUrl } from "../../../utils/dataValidator.js";
export default {
  name: "MkpCartTable",
  components: { MkpTable, MkpButton },
  data: function() {
    return {
      searchQuery: "",
      gridColumns: [
        { key: "label", text: this.$t("cart.table.table.name") },
        { key: "action", text: "" }
      ]
    };
  },
  computed: {
    gridData() {
      return this.$store.getters.getCartContents;
    }
  },
  methods: {
    loadForm(form) {
      this.$store.dispatch("setModalStep", form);
      this.$store.dispatch("setModalState");
    },
    deleteItem(item) {
      let product = {
        id: item.id,
        label: item.label,
        quantity: item.quantity,
        price: item.quantity
      };
      this.$store.dispatch("deleteProduct", {
        url:
          //process.env.VUE_APP_BASE_URL +
          getValidBaseUrl() + process.env.VUE_APP_BASE_URL_API + "/carts",
        product: product
      });
    }
  }
};
</script>

<style scoped></style>
