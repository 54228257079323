<template>
  <section class="container-empty-cart">
    <mkp-text-field>{{ $t("cart.empty.title") }}</mkp-text-field>
    <mkp-button @click="goCatalogue">{{ $t("cart.empty.button") }}</mkp-button>
  </section>
</template>

<script>
import MkpButton from "../../simple/button/mkp-button";
import MkpTextField from "../../simple/text-field/mkp-text-field";
import "./cart-scss/mkp-cart-empty.scss";

export default {
  name: "MkpCartEmpty",
  components: { MkpTextField, MkpButton },
  methods: {
    goCatalogue() {
      this.$router.push({ name: "Catalogue" });
    }
  }
};
</script>

<style scoped></style>
