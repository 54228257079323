<template>
  <table class="table-cart">
    <thead class="title-container">
      <tr role="row" class="title-line">
        <th
          v-for="(column, index) in columns"
          :key="index"
          :class="{ active: sortKey === column.key }"
          class="title-table"
          role="columnheader"
          scope="col"
          @click="sortBy(column.key)"
        >
          {{ column.text | capitalize }}
          <span :class="sortOrders[column] > 0 ? 'asc' : 'dsc'"></span>
        </th>
      </tr>
      <tr class="spacer"></tr>
    </thead>
    <tbody class="table-product">
      <tr v-for="(entry, i) in filteredData" :key="i" class="product-line">
        <td
          v-for="(column, index) in columns"
          :key="index"
          class="product-name"
        >
          {{ entry[column.key] }}
          <slot
            v-if="column.key == 'action'"
            name="actions"
            :row="entry"
          ></slot>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import "./mkp-table.scss";
export default {
  name: "MkpTable",

  filters: {
    capitalize: function(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      }
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      }
    },
    filterKey: {
      type: String,
      default: null
    }
  },
  data: function() {
    let sortOrders = {};
    this.columns.forEach(function(key) {
      sortOrders[key] = 1;
    });
    return {
      sortKey: "",
      sortOrders: sortOrders
    };
  },
  computed: {
    filteredData: function() {
      let sortKey = this.sortKey;
      let filterKey = this.filterKey && this.filterKey.toLowerCase();
      let order = this.sortOrders[sortKey] || 1;
      let data = this.data;
      if (filterKey) {
        data = data.filter(function(row) {
          return Object.keys(row).some(function(key) {
            return (
              String(row[key])
                .toLowerCase()
                .indexOf(filterKey) > -1
            );
          });
        });
      }
      if (sortKey) {
        data = data.slice().sort(function(a, b) {
          a = a[sortKey];
          b = b[sortKey];
          return (a === b ? 0 : a > b ? 1 : -1) * order;
        });
      }
      return data;
    }
  },
  methods: {
    sortBy: function(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
    }
  }
};
</script>

<style scoped></style>
